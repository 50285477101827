<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    :value="value"
    @input="$emit('input', $event)"
    @confirm="$emit('confirm')"
  >
    <div data-testid="followup-dialog-content">
      <strong class="d-block">Trial status</strong>
      Changed from
      <strong data-testid="followup-dialog-content-current-status">{{
        $t('patient-trial-status.' + currentState)
      }}</strong>
      to
      <strong>
        {{ $t('patient-trial-status.in_follow_up') }}
      </strong>
      <WarningBox
        expand
        class="mt-2 mb-1"
        v-if="fromEndTrial"
        data-testid="followup-dialog-content-warning"
      >
        You are setting a patient back to
        <strong>
          {{ $t('patient-trial-status.in_follow_up') }}
        </strong>
        state. <br />

        <strong>Please make sure to activate an app for the patient.</strong>
      </WarningBox>
    </div>
  </BaseConfirmDialog>
</template>

<script>
import BaseConfirmDialog from '../_BaseConfirmDialog'
import WarningBox from '@/components/WarningBox'

export default {
  name: 'FollowUpDialog',
  props: {
    value: { type: Boolean, required: true },
    currentState: { type: String, required: true },
    isLoading: { type: Boolean, required: true },
  },
  components: { BaseConfirmDialog, WarningBox },
  computed: {
    fromEndTrial() {
      return this.currentState === 'completed'
    },
  },
}
</script>
